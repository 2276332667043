<script lang="ts" setup>
import { onMounted, ref, watchEffect } from 'vue'

import { cn } from '../../utils'

import TooltipContent from './tooltip-content.vue'
import TooltipTrigger from './tooltip-trigger.vue'
import Tooltip from './tooltip.vue'

import type { TooltipProps } from '.'

const open = ref(false)

watchEffect(() => {
  if (open.value) {
    for (const el of document.querySelectorAll('.exclude-tooltip-item')) {
      el.classList.add('!overflow-hidden')
    }
  } else {
    for (const el of document.querySelectorAll('.exclude-tooltip-item')) {
      el.classList.remove('!overflow-hidden')
    }
  }
})

const props = withDefaults(defineProps<TooltipProps>(), {
  isDefaultOpen: false,
  triggerAsTag: 'button',
  triggerAsChild: false,
  triggerClass: '',
  contentClass: '',
  contentAlign: 'center',
  contentAlignOffset: undefined,
  contentAsTag: 'div',
  contentArrowPadding: undefined,
  contentArrowClass: '',
  contentSide: 'top',
  contentSideOffset: undefined,
})

onMounted(() => {
  open.value = props.isDefaultOpen
})
</script>

<template>
  <Tooltip v-model:open="open">
    <TooltipTrigger :as-tag="triggerAsTag" :as-child="triggerAsChild" :class="cn(triggerClass)">
      <slot name="trigger" />
    </TooltipTrigger>
    <TooltipContent
      v-if="$slots['content']"
      :side="contentSide"
      :align="contentAlign"
      :align-offset="contentAlignOffset"
      :arrow-padding="contentArrowPadding"
      :arrow-classes="cn(contentArrowClass)"
      :side-offset="contentSideOffset"
      :as-tag="contentAsTag"
      :class="cn(contentClass)"
    >
      <slot name="content" />
    </TooltipContent>
  </Tooltip>
</template>
